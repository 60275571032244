import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default function ScrollToTop() {
  const { pathname, search, hash } = useLocation()

  useEffect(() => {
    if (!hash) window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
  }, [pathname, search, hash])

  return null
}
