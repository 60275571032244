import { CPagination, CPaginationItem } from "@coreui/react"
import React from "react"
import { useDispatch } from "react-redux"
import { setFilterProduct } from "redux/slices/filterProduct"
import "./paginationFn.scss"

const PaginationFn = ({ count, page, filter }) => {
  const dispatch = useDispatch()
  const countingPagination = () => {
    const pagination = Math.ceil(count / 12)
    return pagination
  }

  const changePage = (page) => {
    dispatch(setFilterProduct({ ...filter, page }))
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
  }

  if (countingPagination() === 1) return ""

  return (
    <CPagination className='raphitaPagination' size='sm' align='end'>
      <CPaginationItem aria-label='Previous' disabled={page === 1}>
        <span aria-hidden='true'>&laquo;</span>
      </CPaginationItem>
      {[...Array(countingPagination())].map((e, i) => {
        return (
          <CPaginationItem active={i + 1 === page} key={i} onClick={() => changePage(i + 1)}>
            {i + 1}
          </CPaginationItem>
        )
      })}
      <CPaginationItem aria-label='Next' disabled={page === countingPagination()}>
        <span aria-hidden='true'>&raquo;</span>
      </CPaginationItem>
    </CPagination>
  )
}

export default PaginationFn
