import { createSlice } from "@reduxjs/toolkit"

export const filterProduct = createSlice({
  name: "filterProduct",
  initialState: {
    value: {
      page: 1,
      name: "all",
    },
  },
  reducers: {
    setFilterProduct: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setFilterProduct } = filterProduct.actions
export default filterProduct.reducer
