import { CCol, CContainer, CRow } from "@coreui/react"
import React from "react"
import { Envelope, Telephone, Whatsapp } from "react-bootstrap-icons"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setFilterProduct } from "redux/slices/filterProduct"
import "./footerSection.scss"

const FooterSection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const year = new Date().getFullYear()

  const changeFilterTag = (id, name) => {
    navigate({ pathname: "/p/all", search: `?search=${name}&page=1` })
    dispatch(setFilterProduct({ page: 1, tagId: id, name }))
  }
  const changeFilterBrand = (id, name) => {
    navigate({ pathname: "/p/all", search: `?search=${name}&page=1` })
    dispatch(setFilterProduct({ page: 1, brandId: id, name }))
  }

  const tagList = [
    {
      id: 5,
      name: "Explosion Proof",
    },
    {
      id: 11,
      name: "Street Light",
    },
    {
      id: 21,
      name: "Canopy Light",
    },
    {
      id: 1,
      name: "Industry Light",
    },
  ]
  return (
    <footer className='footer__section'>
      <CContainer className='footer__info'>
        <CRow xs={{ gutterX: 6, gutterY: 13 }} sm={{ gutterX: 6, gutterY: 13 }}>
          <CCol sm={{ span: 12 }} lg={{ span: 5 }} xxl={{ span: 4, offset: 1 }}>
            <div className='footer__left'>
              <h5>PT Raphita Jaya Mandiri</h5>
              <p className='mb-5'>
                Ruko Mutiara Bekasi Center Kav. B-9 JL. Jenderal Ahmad Yani, RT.009/RW.012
                Kayuringin Jaya, Bekasi 17124 Jawa Barat, Indonesia
              </p>
              <div className='linkFooter__wrapper'>
                <div className='contact__wrapper'>
                  <Telephone style={{ transform: "rotate(270deg)" }} />
                  <span>+6221 888 55 999</span>
                </div>
                <div className='contact__wrapper'>
                  <Whatsapp />
                  <span>+6281 1899 419</span>
                </div>
                <div className='contact__wrapper'>
                  <Envelope />
                  <span>raphita@centrin.net.id</span>
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }} xxl={{ span: 3 }}>
            <h5 className='mb-4'>Temukan Produk</h5>
            <div className='linkFooter__wrapper text-center'>
              {tagList.map(({ id, name }) => (
                <span onClick={() => changeFilterTag(id, name)} className='pointer' key={id}>
                  {name}
                </span>
              ))}
            </div>
          </CCol>
          <CCol sm={{ span: 6 }} md={{ span: 7 }} lg={{ span: 3 }} xxl={{ span: 4 }}>
            <CRow>
              <CCol md={7} lg={{ span: 12 }}>
                <div className='companyFooter__wrapper mb-7'>
                  <h5 className='mb-4'>Perusahaan</h5>
                  <div className='linkFooter__wrapper'>
                    <Link to='/about-us'>Perusahaan</Link>
                    <Link to='/contact-us'>Kontak Kami</Link>
                  </div>
                </div>
              </CCol>
              <CCol md={5} lg={{ span: 12 }}>
                <div className='brandFooter__wrapper'>
                  <h5 className='mb-4'>Brand</h5>
                  <div className='linkFooter__wrapper'>
                    <span onClick={() => changeFilterBrand(2, "sinoamigo")} className='pointer'>
                      Sinoamigo
                    </span>
                    <span onClick={() => changeFilterBrand(1, "vyrtych")} className='pointer'>
                      Vyrtych
                    </span>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CContainer>
      <div className='copyright text-center py-4'>
        © Copyright {year} PT Raphita Jaya Mandiri. All Rights Reserved.
      </div>
    </footer>
  )
}

export default FooterSection
