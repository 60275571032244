import { CButton, CCol, CContainer, CRow } from "@coreui/react"
import GalleryProduct from "components/galleryProduct/GalleryProduct"
import CertificationSection from "components/section/certificationSection/CertificationSection"
import OurClientSection from "components/section/clientSection/OurClientSection"
import React from "react"
import "./aboutUs.scss"

const AboutUs = () => {
  return (
    <div className="aboutUs__wrapper headerGradient">
      <CContainer sm md lg xl xxl>
        <div className="__title">
          <CRow className="position-relative">
            <CCol xs={{ span: 12 }} md={{ span: 10 }} lg={{ span: 8 }}>
              <img src="/assets/images/about/aboutUs.webp" alt="About Us" />
            </CCol>
            <CCol
              xs={{ span: 10, offset: 2 }}
              md={{ span: 7, offset: 5 }}
              lg={{ span: 5, offset: 7 }}
              className="floatingCard__wrapper"
            >
              <div className="floating__card">
                <h2>Solusi Penerangan untuk Berbagai Kebutuhan</h2>
                <p>
                  PT Raphita Jaya Mandiri berdiri di Bekasi tahun 2002 sebagai supplier bidang
                  Electrical khususnya Lampu. Sudah dipercaya lebih dari 10 tahun menyediakan lampu
                  untuk berbagai kebutuhan, mulai dari area proyek berbahaya, jalanan dan gedung
                  perkantoran.
                </p>
                <CButton href="/about-us#content">Lihat Selengkapnya</CButton>
              </div>
            </CCol>
          </CRow>
        </div>
        <div className="__content" id="content">
          <CRow lg={{ gutterX: 8 }}>
            <CCol lg={4}>
              <h1>Tentang Kami</h1>
            </CCol>
            <CCol lg={8}>
              <div className="mb-4">
                PT. Raphita Jaya Mandiri berdiri di Bekasi tahun 2002 sebagai supplier bidang
                Electrical khususnya Lampu. Seiring dengan bertambahnya kebutuhan akan barang-barang
                import (khususnya lampu explosion proof) yang belum diproduksi di Indonesia, kami
                mencoba mencari ketersediaan akan barang-barang tersebut di Indonesia, sehingga
                Industry pengguna barang tersebut bisa mendapatkannya dengan mudah.
              </div>
              <div className="mb-4">
                Sejak tahun 2005, kami diangkat dan dipercayai sebagai Agent lampu Industry oleh
                produsen lampu NORDLAND (Pty) Ltd untuk wilayah Indonesia. Sampai saat ini lampu
                Nordland telah banyak dipakai oleh Industry-industry besar di Indonesia.
                Ketersediaan barang yang tepat waktu, keaslian barang dan harga yang kompetitif
                adalah hal yang selalu kami pertahankan.
              </div>
              <div className="mb-4">
                Saat ini, selain Lampu merk Nordland, perusahaan kami juga telah dipercayai oleh
                Produsen lampu dari China yaitu CZ untuk jenis Lampu Industry dan HEDA SOLAR untuk
                General Lighting termasuk pengadaan lampu – lampu yang mempergunakan tenaga Matahari
                (Solar Cell) .
              </div>
              <div className="mb-4">
                Sampai saat ini, Perusahaan kami juga sudah terdaftar sebagai rekanan untuk beberapa
                perusahaan Oil and Gas di Indonesia seperti Pertamina (Persero) , PT. Energi Mega
                Persada Tbk dan beberapa perusahaan lainnya. Untuk Gedung perkantoran, kami juga
                sudah menyuplai lampu kebeberapa Gedung perkantoran seperti Kejaksaan Agung,
                Departemen Dalam Negeri dan Gedung DPR/ MPR. Untuk Prasarana Jalan, Perusahaan kami
                juga telah menyuplai Lampu Hias untuk Lima Wilayah Kota di DKI Jakarta dan Lampu PJU
                untuk Pemda Bekasi.
              </div>
            </CCol>
          </CRow>
        </div>
      </CContainer>
      <GalleryProduct />
      <OurClientSection />
      <CertificationSection />
    </div>
  )
}

export default AboutUs
