import { getProductDetails } from "api/list";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProductDetails } from "redux/slices/productDetails";
import "./productCard.scss";

const ProductCard = (props) => {
  const { imgSrc, name, brand, data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDetails = async () => {
    const {
      data: { data: dataAPI },
    } = await getProductDetails({ id: data.productId });
    dispatch(setProductDetails({ ...dataAPI[0] }));
  };

  const navDetail = () => {
    const sanitizedName = name
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .split(" ")
      .join("-");
    navigate(`/${brand.toLowerCase()}/${sanitizedName}`, { state: data });
    getDetails();
  };

  if (!props) return "";

  return (
    <div className="productCard" onClick={navDetail}>
      <div className="img_wrapper mb-4">
        <img src={imgSrc} alt={name} className="img-fluid" />
      </div>
      <p className="mb-2">{name}</p>
      <span className="text-secondary text-sm">{brand}</span>
    </div>
  );
};

export default ProductCard;
