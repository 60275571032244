import { createSlice } from "@reduxjs/toolkit"

export const productList = createSlice({
  name: "productList",
  initialState: {
    value: false,
  },
  reducers: {
    setProductList: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setProductList } = productList.actions
export default productList.reducer
