import {
  CCollapse,
  CContainer,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
} from "@coreui/react"
import React, { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import "./navbar.scss"

const Navbar = () => {
  const [visible, setVisible] = useState(false)

  return (
    <header>
      <CNavbar expand='lg' className='navigation__navbar'>
        <CContainer>
          <CNavbarBrand className=''>
            <Link to='/'>
              <img
                className='img-fluid'
                src='/assets/images/logo/logoRJM.svg'
                alt='Raphita Jaya Mandiri'
                title='Raphita Jaya Mandiri'
              />
            </Link>
          </CNavbarBrand>
          <CNavbarToggler onClick={() => setVisible(!visible)} />
          <CCollapse className='navbar-collapse' visible={visible}>
            <CNavbarNav className='ms-auto __nav' component='nav'>
              <CNavItem active={false}>
                <NavLink
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  to='/'
                  end
                >
                  Home
                </NavLink>
              </CNavItem>
              <CNavItem>
                <NavLink
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  to='/product'
                >
                  Produk
                </NavLink>
              </CNavItem>
              <CNavItem>
                <NavLink
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  to='/about-us'
                >
                  Tentang Kami
                </NavLink>
              </CNavItem>
              <CNavItem>
                <NavLink
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  to='/contact-us'
                >
                  Kontak Kami
                </NavLink>
              </CNavItem>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </header>
  )
}

export default Navbar
