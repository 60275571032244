import { CCarousel, CCarouselItem, CCol, CContainer, CImage, CRow } from '@coreui/react'
import React from 'react'
import './certificationSection.scss'

const CertificationSection = () => {
  return (
    <div className="certification__section">
      <CContainer>
        <CRow
          className="certification__wrapper"
          xl={{ gutterX: 8 }}
          lg={{ gutterX: 6 }}
          md={{ gutterY: 15 }}
          xs={{ gutterY: 14 }}
        >
          <CCol lg={6}>
            <div className="certification__content">
              <div className="certification__title mb-4">
                <h2 className="mb-0">Sertifikat Partner</h2>
              </div>
              <div className="certification__body mb-7">
                <p className="mb-0">
                  Kami dipercaya sebagai distributor resmi untuk produk{' '}
                  <span className="fw-bold">Vyrtych</span> dan
                  <span className="fw-bold"> Sinoamigo</span>. Menjalin partnership secara resmi
                  sejak 2016, kami memastikan produk yang kami berikan memiliki kualitas dan jaminan
                  terbaik untuk Anda.
                </p>
              </div>
              <div className="certification__logo">
                <img src="/assets/images/logo/logoVyrtych.webp" alt="" />
                <img src="/assets/images/logo/logoSinoamigo.webp" alt="" />
              </div>
            </div>
          </CCol>
          <CCol lg={6}>
            <CCarousel controls dark interval={false}>
              <CCarouselItem>
                <CImage
                  className="d-block carousel-image"
                  src="/assets/images/certification/certificationVyrtych.webp"
                  alt="slide 1"
                />
              </CCarouselItem>
              <CCarouselItem>
                <CImage
                  className="d-block carousel-image"
                  src="/assets/images/certification/certificationSinoamigo.webp"
                  alt="slide 2"
                />
              </CCarouselItem>
            </CCarousel>
          </CCol>
        </CRow>
      </CContainer>
      <div className="bg"></div>
    </div>
  )
}

export default CertificationSection
