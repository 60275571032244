import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Provider } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
import ScrollToTop from "./utils/ScrollToTop";
import { SpeedInsights } from "@vercel/speed-insights/react";

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <App />
      <SpeedInsights />
    </Router>
  </Provider>
);
