import ProductCard from "components/card/productCard/ProductCard"
import PaginationFn from "components/pagination/PaginationFn"
import React from "react"
import { useSelector } from "react-redux"
import { getTitleCase } from "utils/getTitleCase"
import "./productCategoryPage.scss"

const ProductCategoryTitle = ({ search, count = 0 }) => {
  const title = search === "all" ? "Semua Produk" : `Produk kategori "${getTitleCase(search)}"`
  const desc = search
    ? `Terdapat ${count} produk tersedia`
    : "Temukan produk lampu sesuai dengan kebutuhan anda"

  return (
    <div className="productCategory__title">
      <h2>{title}</h2>
      <p className="text-secondary">{desc}</p>
    </div>
  )
}

const ProductCategoryPage = ({ filter }) => {
  const productList = useSelector(({ productList }) => productList.value)

  if (!filter) return ""

  return (
    <div className="contentProductCategory__wrapper">
      <ProductCategoryTitle
        search={filter && filter.name}
        count={productList && productList.count}
      />
      <div className="productCategory__content">
        <div className="productCard__wrapper">
          {productList &&
            productList.rows.map((productItem) => (
              <ProductCard
                name={productItem.product_name}
                imgSrc={productItem.medias.length > 0 && productItem.medias[0].media_url}
                brand={productItem.Brand.brand_name}
                key={productItem.id}
                data={{ productList, productId: productItem.id }}
              />
            ))}
        </div>
        <div className="productCard__pagination">
          <PaginationFn
            count={productList && productList.count}
            page={productList && productList.currentPage.page}
            filter={filter && filter}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductCategoryPage
