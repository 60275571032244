import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react"
import React from "react"
import { Grid } from "react-bootstrap-icons"
import { Link } from "react-router-dom"

const AppBreadcrumb = ({ name = "" }) => {
  return (
    <CBreadcrumb className="mb-6 align-items-center">
      <Grid className="me-1" />
      <CBreadcrumbItem>
        <Link to="/">Home</Link>
      </CBreadcrumbItem>
      <CBreadcrumbItem>
        <Link to={-1}>Product</Link>
      </CBreadcrumbItem>
      <CBreadcrumbItem active>{name}</CBreadcrumbItem>
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
