import { CButton } from "@coreui/react"
import React from "react"
import "./button.scss"

const Button = ({ className, children, icon, type, onClick, color, ...props }) => {
  return (
    <CButton color={color} className={`rBtn ${className}`} type={type} onClick={onClick} {...props}>
      <div className='d-flex align-items-center gap-x-1'>
        {icon}
        {children && <span className='btn-text'>{children}</span>}
      </div>
    </CButton>
  )
}

export default Button
