import { CButton } from "@coreui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFilterProduct } from "redux/slices/filterProduct";
import "./recomendationSection.scss";

const RecomendationSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navProduct = () => {
    navigate({ pathname: "/p/all", search: "?search=all" });
    dispatch(setFilterProduct({ page: 1, name: "all" }));
  };
  return (
    <div className="recomendation__section">
      <div className="recomendation__wrapper">
        <div className="recomendation__content">
          <h2>Temukan Produk yang Tepat</h2>
          <p>
            Temukan solusi penerangan yang tepat untuk kebutuhan anda bersama
            kami. Jangan ragu untuk berdiskusi kepada kami untuk mencari produk
            yang sesuai.
          </p>
          <div className="recomendation__action">
            <CButton onClick={navProduct}>Telusuri Produk</CButton>
            <CButton color="secondary" href="https://wa.me/+62811899419">
              Kontak Kami
            </CButton>
          </div>
        </div>
        <div className="recomendation__image d-flex d-xl-none overflow-hidden">
          <img
            src="/assets/images/recomendation/gasStationNew.webp"
            alt="Shell Station"
          />
        </div>
      </div>
    </div>
  );
};

export default RecomendationSection;
