import React from "react"
import "./tags.scss"

const Tags = ({ children, className = "", ...rest }) => {
  return (
    <span className={`raphitaTag ${className}`} {...rest}>
      <span>{children}</span>
    </span>
  )
}

export default Tags
