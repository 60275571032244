import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProducts as getProd } from "api/list"
import { setProductList } from "redux/slices/productList"

const useGetProducts = () => {
  const dispatch = useDispatch()
  const action = setProductList
  const productList = useSelector(({ productRecomendation }) => productRecomendation.value)

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action])

  useEffect(() => {
    const getProducts = async () => {
      const {
        data: { data },
      } = await getProd({ page: 1 })
      if (data && Object.keys(data).length) doSet(data)
    }
    if (!productList) getProducts()
  }, [productList, doSet])
}

export default useGetProducts
