import { configureStore } from "@reduxjs/toolkit"
import brandList from "./slices/brandList"
import changeState from "./slices/changeState"
import filterProduct from "./slices/filterProduct"
import productDetails from "./slices/productDetails"
import productList from "./slices/productList"
import productRecomendation from "./slices/productRecomendation"
import tagList from "./slices/tagList"

export default configureStore({
  reducer: {
    changeState,
    brandList,
    productList,
    tagList,
    filterProduct,
    productRecomendation,
    productDetails,
  },
})
