import { CContainer } from "@coreui/react";
import { getProductDetails } from "api/list";
import Button from "components/button/Button";
import { ProductCard } from "components/card";
import RaphitaCarousel from "components/carousel/RaphitaCarousel";
import Tags from "components/tags/Tags";
import React, { useCallback, useEffect } from "react";
import {
  BrightnessHigh,
  ChevronDoubleDown,
  FilePdf,
  House,
  Lightning,
  Water,
  Whatsapp,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setProductDetails } from "redux/slices/productDetails";
import useGetBrandList from "utils/hooks/useGetBrandList";
import useGetProducts from "utils/hooks/useGetProducts";
import "./productDetails.scss";

const RowSet = ({ list = [] }) => {
  return list.map(({ value, icon: Icon }, i) => {
    return (
      value !== "-" && (
        <div key={i} className="detailSpecification">
          <Icon />
          <span>{value}</span>
        </div>
      )
    );
  });
};

const ProductDetailsNew = () => {
  useGetBrandList();
  useGetProducts();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { brand } = useParams();
  const brandList = useSelector(({ brandList }) => brandList.value);
  const productDetails = useSelector(
    ({ productDetails }) => productDetails.value
  );
  const productList = useSelector(({ productList }) => productList.value);
  const navigate = useNavigate();

  const filtering =
    productList &&
    productList.rows.filter((item) => item.id !== productDetails.id);
  const countFilter = Math.floor(Math.random() * (filtering.length - 4));
  const endFilter =
    countFilter > filtering.length - 4 ? countFilter - 4 : countFilter;

  const getDetails = useCallback(async () => {
    const {
      data: { data },
    } = await getProductDetails({ id: state.productId });
    dispatch(setProductDetails({ ...data[0] }));
  }, [state.productId, dispatch]);

  useEffect(() => {
    if (!productDetails) getDetails();
  }, [getDetails, productDetails]);

  useEffect(() => {
    if (brandList) {
      const match = brandList.filter(
        ({ brand_name }) =>
          brand_name.toLowerCase() ===
          decodeURIComponent(brand).toLocaleLowerCase()
      );
      if (match.length < 1) navigate("/p/all");
    }
  }, [brandList, brand, navigate]);

  if (productDetails)
    return (
      <CContainer fluid>
        <div className="productSummary">
          <div className="productSummary__image">
            <RaphitaCarousel images={productDetails.medias} />
          </div>
          <div className="productSummary__content">
            <div className="productHeader">
              <div className="productTitle">
                <h1 className="mb-0">{productDetails.product_name}</h1>
              </div>
              <div className="productBrand">
                <span>Brand:</span>
                <img
                  src={productDetails.Brand.logo}
                  className="img-fluid"
                  style={{ maxHeight: "17px" }}
                  alt="Vrytych"
                />
              </div>
            </div>
            <div className="productContent">
              <div className="productDescription">
                <p className="mb-0">{productDetails.summary}</p>
              </div>
              <div className="productSpec__wrapper w-100">
                <RowSet
                  list={[
                    { value: productDetails.brightness, icon: BrightnessHigh },
                    { value: productDetails.watt, icon: Lightning },
                    { value: productDetails.type, icon: House },
                    {
                      value: productDetails.materials,
                      icon: ChevronDoubleDown,
                    },
                    { value: productDetails.iprating, icon: Water },
                  ]}
                />
              </div>
              <div className="productAction">
                <Button
                  color="dark"
                  icon={<Whatsapp />}
                  href="https://wa.me/+62811899419"
                >
                  Tanya Ketersediaan
                </Button>
                <Button
                  color="light"
                  icon={<FilePdf />}
                  href={productDetails.subtitle}
                >
                  Download Manual
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="productTab">
          <h2>Deskripsi Produk</h2>
          <div className="divider"></div>
          <div
            dangerouslySetInnerHTML={{ __html: productDetails.description }}
          />
        </div>
        <div className="productTags">
          <p className="mb-0">Tags: </p>
          <div className="tags">
            {productDetails.tags.map((item) => (
              <Tags key={item.id}>{item.tag_name}</Tags>
            ))}
          </div>
        </div>
        <div className="productRecomendation">
          <div className="__title">
            <h2 className="mb-0">Produk terkait</h2>
            <Link to="/p/all?search=all">Lihat Semua</Link>
          </div>
          <div className="divider" />
          <div className="__content">
            {filtering &&
              filtering
                .slice(endFilter, endFilter + 4)
                .map((item, i) => (
                  <ProductCard
                    name={item.product_name}
                    imgSrc={item.medias.length > 0 && item.medias[0].media_url}
                    brand={item.Brand.brand_name}
                    key={item.id}
                    data={{ productList, productId: item.id }}
                  />
                ))}
          </div>
        </div>
      </CContainer>
    );
};

export default ProductDetailsNew;
