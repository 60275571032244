import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTags } from "api/list"
import { setTagList } from "redux/slices/tagList"

const useGetTagList = () => {
  const dispatch = useDispatch()
  const action = setTagList
  const tagList = useSelector(({ tagList }) => tagList.value)

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action])

  useEffect(() => {
    const getTagList = async () => {
      const {
        data: { data },
      } = await getTags()
      if (data && Object.keys(data).length) doSet(data)
    }
    if (!tagList) getTagList()
  }, [tagList, doSet])
}

export default useGetTagList
