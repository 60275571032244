import instance from "./instance"

export const getProducts = ({ page, brandId, tagId }) => {
  const data = instance.get("/products", {
    params: { page, brandId, tagId },
  })
  return data
}

export const getProductRecommendation = () => {
  const data = instance.get("/product-recommendation")
  return data
}

export const getProductById = ({ page, brandId }) => {
  const data = instance.get("/products", {
    params: { page, brandId },
  })
  return data
}

export const getBrands = () => {
  const data = instance.get("/brands")
  return data
}

export const getTags = () => {
  const data = instance.get("/tags")
  return data
}

export const getProductTable = () => {
  const data = instance.get("/product-test")
  return data
}

export const getProductDetails = ({ id }) => {
  const data = instance.get("/product", {
    params: { productID: id },
  })
  return data
}
