import { createSlice } from "@reduxjs/toolkit"

export const brandList = createSlice({
  name: "brandList",
  initialState: {
    value: false,
  },
  reducers: {
    setBrandList: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setBrandList } = brandList.actions
export default brandList.reducer
