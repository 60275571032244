import { createSlice } from "@reduxjs/toolkit"

export const tagList = createSlice({
  name: "tagList",
  initialState: {
    value: false,
  },
  reducers: {
    setTagList: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setTagList } = tagList.actions
export default tagList.reducer
