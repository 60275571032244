import { CContainer } from "@coreui/react"
import React from "react"
import { Building, Clock, Envelope, Phone, PinMap } from "react-bootstrap-icons"
import { CategoryCard } from "components/card"
import RecomendationSection from "components/section/recomendationSection/RecomendationSection"
import "./contactUs.scss"

const ContactUs = () => {
  return (
    <div className='contactUs__wrapper headerGradient'>
      <CContainer fluid>
        <div className='__header w-100'>
          <div className='__title ms-20'>
            <h1 className='mb-3'>Kontak Kami</h1>
            <p className='text-secondary mb-10'>
              Konsultasi & tanya jawab seputar kebutuhan penerangan anda dengan kami
            </p>
            <div className='d-flex flex-column justify-content-start gap-6'>
              <h4>PT Raphita Jaya mandiri</h4>
              <div className='d-flex '>
                <Building className='me-10' />
                <div className='__content'>
                  <div className='mb-0'>Alamat</div>
                  <p className='mb-0'>
                    Ruko Mutiara Bekasi Center Kav. B-9 JL. Jenderal Ahmad Yani, RT.009/RW.012
                    Kayuringin Jaya, Bekasi 17124 Jawa Barat, Indonesia
                  </p>
                </div>
              </div>
              <div className='d-flex'>
                <PinMap className='me-10' />
                <div className='__content'>
                  <div className='mb-0'>Google Maps</div>
                  <a href='https://goo.gl/maps/ytgts8Xj2vR7ijPS7' className='mb-0'>
                    https://goo.gl/maps/ytgts8Xj2vR7ijPS7
                  </a>
                </div>
              </div>
              <div className='d-flex '>
                <Phone className='me-10' />
                <div className='__content'>
                  <div className='mb-0'>Telepon & WhatsApp</div>
                  <p className='mb-0'>+6281 1899 419</p>
                </div>
              </div>
              <div className='d-flex '>
                <Envelope className='me-10' />
                <div className='__content'>
                  <div className='mb-0'>Email</div>
                  <a href='mailto:raphita@centrin.net.id' className='mb-0'>
                    raphita@centrin.net.id
                  </a>
                </div>
              </div>
              <div className='d-flex '>
                <Clock className='me-10' />
                <div className='__content'>
                  <div className='mb-0'>Operasional</div>
                  <p className='mb-0'>08.00 - 17.00 WIB</p>
                </div>
              </div>
            </div>
          </div>
          <div className='__image d-sm-none d-md-block'>
            <img src='/assets/images/contact/contactUs.webp' alt='' />
          </div>
        </div>
        <div className='__category'>
          <CategoryCard />
        </div>
        <RecomendationSection />
      </CContainer>
    </div>
  )
}

export default ContactUs
