import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from "@coreui/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import { AppSidebarNav } from "./AppSidebarNav"

// sidebar nav config
import { Link } from "react-router-dom"
import { setChangeState } from "redux/slices/changeState"
import navigation from "../_nav"

const AppSidebar = () => {
  const dispatch = useDispatch()

  const changeState = useSelector(({ changeState }) => changeState.value)

  return (
    <CSidebar
      position="fixed"
      unfoldable={changeState.sidebarUnfoldable}
      visible={changeState.sidebarShow}
      onShow={(e) => {
        if (window.innerWidth > 991) return true
      }}
      onHide={(e) => {
        if (window.innerWidth < 992) return true
      }}
      onVisibleChange={(visible) => {
        dispatch(setChangeState({ ...changeState, sidebarShow: visible }))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <Link to="/a/dashboard" className="text-medium-emphasis">
          <img
            className="img-fluid"
            src="/assets/images/logo/logoRJM.svg"
            alt="Raphita Jaya Mandiri"
            title="Raphita Jaya Mandiri"
          />
        </Link>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch(
            setChangeState({ ...changeState, sidebarUnfoldable: !changeState.sidebarUnfoldable })
          )
        }
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
