import { createSlice } from "@reduxjs/toolkit"

export const productRecommendation = createSlice({
  name: "productRecommendation",
  initialState: {
    value: false,
  },
  reducers: {
    setProductRecommendation: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setProductRecommendation } = productRecommendation.actions
export default productRecommendation.reducer
