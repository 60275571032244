import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductRecommendation as getRecomendation } from "api/list"
import { setProductRecommendation } from "redux/slices/productRecomendation"

const useGetProductRecomendation = () => {
  const dispatch = useDispatch()
  const action = setProductRecommendation
  const productRecommendation = useSelector(
    ({ productRecomendation }) => productRecomendation.value
  )

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action])

  useEffect(() => {
    const getProductRecommendation = async () => {
      const {
        data: { data },
      } = await getRecomendation()
      if (data && Object.keys(data).length) doSet(data)
    }
    if (!productRecommendation) getProductRecommendation()
  }, [productRecommendation, doSet])
}

export default useGetProductRecomendation
