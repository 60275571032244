/*eslint no-eval: "error"*/
import axios from "axios";
import { getCookie } from "utils/helpers";

function getLocalAccessToken() {
  const accessToken = getCookie("accessToken");
  return accessToken;
}

function getLocalRefreshToken() {
  const refreshToken = getCookie("refreshToken");
  return refreshToken;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_PRODUCTION,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

const refreshToken = instance.post("/refresh", {
  refreshToken: getLocalRefreshToken(),
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken();
          const { accessToken } = rs.data;
          window.localStorage.setItem("accessToken", accessToken);
          instance.defaults.headers.common["x-access-token"] = accessToken;

          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
