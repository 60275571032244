import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getProducts } from "api/list";
import Tags from "components/tags/Tags";
import { setFilterProduct } from "redux/slices/filterProduct";
import { setProductList } from "redux/slices/productList";
import useGetBrandList from "utils/hooks/useGetBrandList";
import useGetTagList from "utils/hooks/useGetTagList";
import ProductCategoryPage from "views/homepage/product/productCategory/ProductCategoryPage";
import "./layoutProductCategoryPage.scss";

const SidebarCategory = ({ filter }) => {
  useGetBrandList();
  useGetTagList();
  const dispatch = useDispatch();
  const brandList = useSelector(({ brandList }) => brandList.value);
  const tagList = useSelector(({ tagList }) => tagList.value);
  const [_, setSearch] = useSearchParams();
  const changeFilterBrand = (id, name) => {
    setSearch({ search: name, page: 1 });
    dispatch(setFilterProduct({ page: 1, brandId: id, name }));
  };
  const changeFilterTag = (id, name) => {
    setSearch({ search: name, page: 1 });
    dispatch(setFilterProduct({ page: 1, tagId: id, name }));
  };

  const categoryList = [
    { id: 5, name: "Explosion Proof" },
    { id: 11, name: "Street Light" },
    { id: 21, name: "Canopy Light" },
    { id: 1, name: "Industry Light" },
    { id: 17, name: "Highbay Light" },
  ];

  return (
    <div className="sidebarCategory">
      <div className="sidebarCategory__category">
        <h5 className="mb-0">Kategori</h5>
        <div className="divider" />
        <div className="link-wrapper">
          {categoryList.map(({ id, name }) => (
            <span
              onClick={() => changeFilterTag(id, name)}
              className="text-secondary text-sm pointer"
              key={id}
            >
              {name}
            </span>
          ))}
        </div>
      </div>
      <div className="sidebarCategory__brand">
        <h5 className="mb-0">Brand</h5>
        <div className="divider" />
        <div className="link-wrapper">
          {brandList &&
            brandList.map((brand) => (
              <span
                onClick={() => changeFilterBrand(brand.id, brand.brand_name)}
                className="text-secondary text-sm pointer"
                key={brand.id}
              >
                {brand.brand_name}
              </span>
            ))}
        </div>
      </div>
      <div className="sidebarCategory__tag">
        <h5 className="mb-0">Tags</h5>
        <div className="divider" />
        <div className="tagList">
          {tagList &&
            tagList.map((tag) => (
              <Tags
                key={tag.id}
                className="pointer"
                onClick={() => changeFilterTag(tag.id, tag.tag_name)}
              >
                {tag.tag_name}
              </Tags>
            ))}
        </div>
      </div>
    </div>
  );
};

const LayoutProductCategoryPage = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ filterProduct }) => filterProduct.value);

  const getProductList = useCallback(async () => {
    const {
      data: { data },
    } = await getProducts(filter);
    dispatch(setProductList(data));
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter) getProductList();
  }, [getProductList, filter]);

  return (
    <div className="productPage__layout">
      <CContainer>
        <CRow lg={{ gutterX: 10 }} xxl={{ gutterX: 15 }}>
          <CCol sm={{ order: 2, span: 12 }} lg={{ span: 3, order: 1 }} xl={3}>
            <SidebarCategory filter={filter} />
          </CCol>
          <CCol
            sm={{ order: 1, span: 12 }}
            md={12}
            lg={{ span: 9, order: 2 }}
            xl={9}
          >
            <ProductCategoryPage filter={filter} />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LayoutProductCategoryPage;
