import { createSlice } from "@reduxjs/toolkit"

export const productDetails = createSlice({
  name: "productDetails",
  initialState: {
    value: false,
  },
  reducers: {
    // reducers action
    setProductDetails: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setProductDetails } = productDetails.actions
export default productDetails.reducer
