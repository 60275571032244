import { CFooter } from "@coreui/react"
import React from "react"

const AppFooter = () => {
  const year = new Date().getFullYear()

  return (
    <CFooter className="justify-content-center">
      <div>© Copyright {year} PT Raphita Jaya Mandiri. All Rights Reserved.</div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
