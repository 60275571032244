import React, { Suspense } from "react";
import Routing from "./Routing";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routing />
    </Suspense>
  );
};

export default App;
