import { CContainer } from "@coreui/react"
import React from "react"
import "./galleryProduct.scss"

const GalleryProduct = () => {
  return (
    <div className='galleryProduct__wrapper'>
      <CContainer fluid>
        <div className='galleryProduct'>
          <div className='gallery gallery1'>
            <img src='/assets/images/gallery/gallery2.webp' alt='Gallery 1' />
            <div className='overlay-img'>Lampu Basement</div>
          </div>
          <div className='gallery gallery2'>
            <img src='/assets/images/gallery/gallery5.webp' alt='Gallery 2' />
            <div className='overlay-img'>Lampu Outdoor</div>
          </div>
          <div className='gallery gallery3 d-none d-lg-block'>
            <img src='/assets/images/gallery/gallery4.webp' alt='Gallery 4' />
            <div className='overlay-img'>Lampu Terowongan</div>
          </div>
          <div className='gallery gallery4 d-none d-lg-block'>
            <img src='/assets/images/gallery/gallery3.webp' alt='Gallery 3' />
            <div className='overlay-img'>Lampu Stasiun</div>
          </div>
          <div className='gallery gallery5'>
            <img src='/assets/images/gallery/gallery6.webp' alt='Gallery 5' />
            <div className='overlay-img'>Lampu SPBU</div>
          </div>
          <div className='gallery gallery6'>
            <img src='/assets/images/gallery/gallery1.webp' alt='Gallery 6' />
            <div className='overlay-img'>Lampu Proyek</div>
          </div>
        </div>
      </CContainer>
    </div>
  )
}

export default GalleryProduct
