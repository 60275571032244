import { CButton, CCol, CRow } from "@coreui/react"
import React from "react"
import FormProduct from "components/form/FormProduct"
import "./productActions.scss"

const AddProducts = () => {
  return (
    <div className='productActions'>
      <div className='headerSection'>
        <div className='headerSection__wrapper'>
          <div className='headerSection-title'>
            <h1>Tambah Product</h1>
          </div>
        </div>
      </div>
      <CRow xl={{ gutter: 4 }}>
        <CCol xl={9}>
          <div className='productForm'>
            <FormProduct />
          </div>
        </CCol>
        <CCol xl={3}>
          <div className='productStatus'>
            <div className='productStatus__wrapper'>
              <div className='productStatus-title'>
                <h2>Product Status</h2>
              </div>
              <div className='productStatus-content'>
                <div className='contentStatus'>
                  <span>Status</span>
                  <span>Published</span>
                </div>
                <div className='contentPublish'>
                  <span>Publish Date</span>
                  <span>25 Sep 2022 at 11:15 AM</span>
                </div>
                <div className='contentAction'>
                  <CButton variant='ghost'>Set as draft</CButton>
                  <CButton>Update</CButton>
                </div>
              </div>
            </div>
          </div>
          <div className='productInfo'>
            <div className='productInfo__wrapper'>
              <div className='productStatus-title'>
                <h2>Product Status</h2>
              </div>
              <div className='productStatus-content'>
                <div className='contentStatus'>
                  <span>Status</span>
                  <span>Published</span>
                </div>
                <div className='contentPublish'>
                  <span>Publish Date</span>
                  <span>25 Sep 2022 at 11:15 AM</span>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  )
}

export default AddProducts
