import React from "react"
import { BoundingBox, Building, FileExcel, FullscreenExit, Hexagon } from "react-bootstrap-icons"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setFilterProduct } from "redux/slices/filterProduct"
import "./categoryCard.scss"

const categoryList = [
  { name: "Explosion Proof", id: 5, icon: <FileExcel size={40} />, to: "explosion-proof" },
  { name: "Street Light", id: 11, icon: <FullscreenExit size={40} />, to: "street-light" },
  { name: "Canopy Light", id: 21, icon: <BoundingBox size={40} />, to: "canopy-light" },
  { name: "Industry Light", id: 1, icon: <Building size={40} />, to: "warehouse-light" },
  { name: "Highbay Light", id: 17, icon: <Hexagon size={40} />, to: "high-bay" },
]

const CategoryCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navCategory = (name, id) => {
    navigate({ pathname: `/p/all`, search: `?search=${name}` })
    dispatch(setFilterProduct({ page: 1, tagId: id, name }))
  }
  return (
    <div className='categoryCard__wrapper'>
      {categoryList.map(({ name, icon, to, id }) => (
        <div className='categoryCard' key={name} onClick={() => navCategory(name, id)}>
          {icon}
          <h5>{name}</h5>
        </div>
      ))}
    </div>
  )
}

export default CategoryCard
