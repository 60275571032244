import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBrands } from "api/list"
import { setBrandList } from "redux/slices/brandList"

const useGetBrandList = () => {
  const dispatch = useDispatch()
  const action = setBrandList
  const brandList = useSelector(({ brandList }) => brandList.value)

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action])

  useEffect(() => {
    const getBrandList = async () => {
      const {
        data: { data },
      } = await getBrands()
      if (data && Object.keys(data).length) doSet(data)
    }
    if (!brandList) getBrandList()
  }, [brandList, doSet])
}

export default useGetBrandList
