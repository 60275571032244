import { cilBasket, cilImage, cilSpeedometer, cilTags } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { CNavItem } from "@coreui/react"
import React from "react"

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Media",
    to: "media",
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Products",
    to: "products",
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Brands",
    to: "brands",
    icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
  },
]

export default _nav
