import { createSlice } from "@reduxjs/toolkit"

export const changeState = createSlice({
  name: "changeState",
  initialState: {
    value: {
      sidebarUnfoldable: false,
      sidebarShow: true,
    },
  },
  reducers: {
    // reducers action
    setChangeState: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setChangeState } = changeState.actions
export default changeState.reducer
