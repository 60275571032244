import { CCol, CContainer, CRow } from '@coreui/react'
import React from 'react'
import './ourClientSection.scss'

const OurClientSection = () => {
  const lists = [
    { src: 'jasamarga.webp', name: 'PT Jasa Marga' },
    { src: 'pertamina.webp', name: 'PT Pertamina' },
    { src: 'shell.webp', name: 'Shell' },
    { src: 'pupr.webp', name: 'Kementerian Pekerjaan Umum dan Perumahan Rakyat' },
  ]
  return (
    <CContainer fluid className="ourClient__wrapper text-center">
      <div className="ourClient__title mb-9">
        <h2>Klien Kami</h2>
        <p className="m-0">Perusahaan yang sudah mempercayai kami untuk bekerja sama</p>
      </div>
      <CRow
        className="align-items-center justify-content-between"
        xs={{ gutter: 10 }}
        xl={{ gutterX: 20 }}
      >
        {lists.map(({ src, name }) => (
          <CCol xs={6} lg={3} key={name}>
            <img
              className="img-fluid"
              src={`/assets/images/clients/${src}`}
              title={name}
              alt={name}
            />
          </CCol>
        ))}
      </CRow>
    </CContainer>
  )
}

export default OurClientSection
