import FooterSection from "components/footer/FooterSection"
import Navbar from "components/navigation/Navbar"
import React from "react"
import { Outlet } from "react-router-dom"

const LayoutUser = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <FooterSection />
    </>
  )
}

export default LayoutUser
