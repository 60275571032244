import React from "react"
import { Carousel } from "react-responsive-carousel"
import "./raphitaCarousel.scss"

const RaphitaCarousel = ({ images }) => {
  return (
    <Carousel
      className='react-carousel'
      showArrows={false}
      showIndicators={false}
      showStatus={false}
    >
      {images.map((image, i) => (
        <div key={image.id} className='image-wrapper'>
          <img src={image.media_url} alt='' />
        </div>
      ))}
    </Carousel>
  )
}

export default RaphitaCarousel
