import { CForm, CFormInput, CFormTextarea } from "@coreui/react"
import { convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import Button from "../button/Button"
import "./formProduct.scss"

const FormProduct = () => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [convertedContent, setConvertedContent] = useState(null)

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent())
    setConvertedContent(html)
  }, [editorState])

  console.log(convertedContent)

  return (
    <Formik
      initialValues={{ summaryProduct: "" }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2))
          actions.setSubmitting(false)
        }, 1000)
      }}
    >
      {(props) => (
        <CForm className="product__form" onSubmit={props.handleSubmit}>
          <CFormInput name="title" value={props.values.title} onChange={props.handleChange} />
          <CFormTextarea
            name="summaryProduct"
            value={props.values.summaryProduct}
            onChange={props.handleChange}
            label="Summary Product"
            rows={3}
          />
          <CFormTextarea
            name="summaryProduct"
            value={props.values.summaryProduct}
            onChange={props.handleChange}
            label="Summary Product"
            rows={3}
          />
          <Editor editorState={editorState} onEditorStateChange={setEditorState} />
          <Button type="submit">Submit</Button>
        </CForm>
      )}
    </Formik>
  )
}

export default FormProduct
