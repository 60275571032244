import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { CategoryCard } from "components/card";
import ProductCard from "components/card/productCard/ProductCard";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFilterProduct } from "redux/slices/filterProduct";
import useGetProductRecomendation from "utils/hooks/useGetProductRecomendation";
import useGetProducts from "utils/hooks/useGetProducts";
import useGetTagList from "utils/hooks/useGetTagList";
import "./productPage.scss";

const ProductPage = () => {
  useGetTagList();
  useGetProductRecomendation();
  useGetProducts();
  const dispatch = useDispatch();
  const filter = useSelector(({ filterProduct }) => filterProduct.value);
  const productRecomendation = useSelector(
    ({ productRecomendation }) => productRecomendation.value
  );
  const productList = useSelector(({ productList }) => productList.value);
  const navigate = useNavigate();
  const nav = (to, id) => {
    navigate({ pathname: "/p/all", search: `?search=${to}` });
    dispatch(setFilterProduct({ ...filter, brandId: id, name: to }));
  };
  const navAll = () => {
    navigate({ pathname: "/p/all", search: "?search=all&page=1" });
    dispatch(setFilterProduct({ page: 1, name: "all" }));
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  return (
    <div className="productPage__wrapper">
      <CContainer>
        <div className="titleSection ">
          <h1>Produk Kami</h1>
          <p>Temukan produk lampu yang sesuai dengan kebutuhan anda</p>
        </div>
        <div className="brandSection ">
          <h4>Temukan berdasarkan brand</h4>
          <div className="divider"></div>
          <div className="brandSection__img">
            <img
              className="img-fluid"
              src="/assets/images/logo/vyrtych32.webp"
              alt="Vyrtych Logo"
              title="Vyrtych Logo"
              onClick={() => nav("vyrtych", 1)}
            />
            <img
              className="img-fluid"
              src="/assets/images/logo/sinoamigo32.webp"
              alt="Sinoamigo Logo"
              title="Sinoamigo Logo"
              onClick={() => nav("sinoamigo", 2)}
            />
            <img
              className="img-fluid"
              style={{ maxHeight: "32px" }}
              src="/assets/images/logo/tcm.webp"
              alt="TCM Logo"
              title="TCM Logo"
              onClick={() => nav("TCM", 3)}
            />
            <img
              className="img-fluid"
              src="/assets/images/logo/zgsm.webp"
              alt="ZGSM Logo"
              title="ZGSM Logo"
              onClick={() => nav("ZGSM", 4)}
            />
          </div>
        </div>
        <div className="categorySection ">
          <h4>Temukan berdasarkan kategori</h4>
          <div className="divider"></div>
          <CategoryCard />
        </div>
        <div className="productSection ">
          <h4>Produk rekomendasi</h4>
          <div className="divider"></div>
          <CRow
            xs={{ gutterX: 4, gutterY: 8 }}
            md={{ gutter: 4 }}
            lg={{ gutterX: 7, gutterY: 8 }}
            xl={{ gutterX: 10, gutterY: 8 }}
          >
            {productRecomendation &&
              productRecomendation.map((product) => (
                <CCol
                  xs={{ span: 6 }}
                  md={{ span: 4 }}
                  lg={{ span: 3 }}
                  key={product.id}
                >
                  <ProductCard
                    name={product.product_name}
                    imgSrc={
                      product.medias.length > 0 && product.medias[0].media_url
                    }
                    brand={product.Brand.brand_name}
                    key={product.id}
                    data={{ productList, productId: product.id }}
                  />
                </CCol>
              ))}
          </CRow>
          <CButton className="d-flex m-auto" onClick={navAll}>
            Lihat semua produk
          </CButton>
        </div>
      </CContainer>
    </div>
  );
};

export default ProductPage;
