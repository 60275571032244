import LayoutUser from "layout/LayoutUser"
import LayoutProductDetail from "layout/productDetail/LayoutProductDetail"
import LayoutProductCategoryPage from "layout/productPageCategory/LayoutProductCategoryPage"
import React from "react"
import { Route, Routes } from "react-router-dom"
import AboutUs from "views/homepage/aboutUs/AboutUs"
import ContactUs from "views/homepage/contactUs/ContactUs"
import ProductPage from "views/homepage/product/ProductPage"
import Login from "views/pages/login/Login"
import ProductDetails from "views/products/ProductDetails"
import routes from "./routes"

const Homepage = React.lazy(() => import("views/homepage/home/Homepage"))
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"))
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"))

const Routing = () => {
  return (
    <Routes>
      <Route path="a">
        <Route index element={<Login />} />
        <Route element={<DefaultLayout />}>
          {routes.map(({ path, element }, i) => {
            return <Route path={path} element={element} key={i} />
          })}
        </Route>
      </Route>
      <Route element={<LayoutUser />}>
        <Route path="/" name="homepage" element={<Homepage />} />
        <Route path="product" name="produk">
          <Route index element={<ProductPage />} />
        </Route>
        <Route path="p" name="Product">
          <Route path="all" element={<LayoutProductCategoryPage />} />
        </Route>
        <Route path=":brand" element={<LayoutProductDetail />}>
          <Route path=":name" name="Detail" element={<ProductDetails />} />
        </Route>
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
      </Route>
      <Route path="*" name="Page 404" element={<Page404 />} />
    </Routes>
  )
}

export default Routing
