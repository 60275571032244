import { CContainer } from "@coreui/react";
import { AppBreadcrumb } from "components";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./layoutProductDetail.scss";

const LayoutProductDetail = () => {
  const { state } = useLocation();

  const productDetails = useSelector(
    ({ productDetails }) => productDetails.value
  );

  if (!state) return <Navigate to="/p/all" />;
  return (
    <div className="productDetail__layout">
      <CContainer>
        <AppBreadcrumb name={productDetails.product_name} />
        <Outlet />
      </CContainer>
    </div>
  );
};

export default LayoutProductDetail;
